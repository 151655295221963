import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PaginationComponent } from '../../app/base/pagination/pagination.component';
import { ScannerComponent } from '../../app/base/scanner/scanner.component';

// Pipes
import { DateFormatPipe } from '../core/pips/date-format.pipe'; 
import { SafePipe } from '../core/pips/safe.pipe';
/* Directive */
import { NumberOnlyDirective } from '../../app/core/directives/number-only.directive';


@NgModule({
  declarations: [
    BreadcrumbsComponent,
    NavbarComponent,
    PaginationComponent,
    ScannerComponent,
    DateFormatPipe,
    SafePipe,
    NumberOnlyDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    BreadcrumbsComponent,
    NavbarComponent,
    PaginationComponent,
    ScannerComponent,
    DateFormatPipe,
    SafePipe
  ]
})
export class SharedModule { }
