import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { BaseService } from './base.service';


@Injectable({
    providedIn: 'root'
})

export class DashboardService {

    controller = 'DashBoard';
    constructor(private base: BaseService) {}

    getDashBoard(idString: any) {
        return this.base.gets(this.controller + '/GetDashBoard/' + idString)
    }

    getDashBoardData(userId: any) {
        return this.base.gets(this.controller + '/getDashBoardData/' + userId)
    }
}