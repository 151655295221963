import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[NumbersOnly]'
})
export class NumberOnlyDirective {
  @Input() allowDecimals = true;
  @Input() allowSign = false;
  @Input() decimalSeparator = '.';

  previousValue = '';

  // Regular expressions
  integerUnsigned = '^[0-9]*$';
  integerSigned = '^-?[0-9]+$';
  decimalUnsigned = '^[0-9]+(.[0-9]+)?$';
  decimalSigned = '^-?[0-9]+(.[0-9]+)?$';

  constructor(private hostElement: ElementRef) {}

  @HostListener('change')
  onChange(): void {
    this.validateValue(this.hostElement.nativeElement.value);
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent): void {
    const value = e.clipboardData?.getData('text/plain') || '';
    this.validateValue(value);
    e.preventDefault();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent): void {
    const inputElement = e.target as HTMLInputElement;
    const cursorPosition: number = inputElement.selectionStart || 0;
    const originalValue: string = inputElement.value;
    const key: string = this.getName(e);
    const controlOrCommand = e.ctrlKey === true || e.metaKey === true;
    const signExists = originalValue.includes('-');
    const separatorExists = originalValue.includes(this.decimalSeparator);

    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Escape',
      'Tab',
      'Home',
      'End',
      'Delete'
    ];

    const separatorIsCloseToSign = signExists && cursorPosition <= 1;
    if (this.allowDecimals && !separatorIsCloseToSign && !separatorExists) {
      allowedKeys.push(this.decimalSeparator);
    }

    const firstCharacterIsSeparator = originalValue.charAt(0) !== this.decimalSeparator;
    if (this.allowSign && !signExists && firstCharacterIsSeparator && cursorPosition === 0) {
      allowedKeys.push('-');
    }

    if (
      allowedKeys.indexOf(key) !== -1 ||
      (key === 'a' && controlOrCommand) ||
      (key === 'c' && controlOrCommand) ||
      (key === 'v' && controlOrCommand) ||
      (key === 'x' && controlOrCommand)
    ) {
      return;
    }

    this.previousValue = originalValue;

    const isNumber = new RegExp(this.integerUnsigned).test(key);
    if (!isNumber) {
      e.preventDefault();
    }
  }

  validateValue(value: string): void {
    // Initialize regex with a default value
    let regex = this.integerUnsigned;

    if (!this.allowDecimals && this.allowSign) {
      regex = this.integerSigned;
    }
    if (this.allowDecimals && !this.allowSign) {
      regex = this.decimalUnsigned;
    }
    if (this.allowDecimals && this.allowSign) {
      regex = this.decimalSigned;
    }

    if (value.charAt(0) === this.decimalSeparator) {
      value = '0' + value;
    }

    if (value.charAt(value.length - 1) === this.decimalSeparator) {
      value = value + '0';
    }

    const valid: boolean = new RegExp(regex).test(value);
    this.hostElement.nativeElement.value = valid ? value : '0';
  }

  getName(e: KeyboardEvent): string {
    if (e.key) {
      return e.key;
    } else {
      if (e.keyCode && String.fromCharCode) {
        switch (e.keyCode) {
          case 8:
            return 'Backspace';
          case 9:
            return 'Tab';
          case 27:
            return 'Escape';
          case 37:
            return 'ArrowLeft';
          case 39:
            return 'ArrowRight';
          case 36:
            return 'Home';
          case 35:
            return 'End';
          case 46:
            return 'Delete';
          case 188:
            return ',';
          case 190:
            return '.';
          case 109:
            return '-';
          case 173:
            return '-';
          case 189:
            return '-';
          default:
            return String.fromCharCode(e.keyCode);
        }
      }
    }
    return '';
  }
}