<!--start back-to-top-->
<button (click)="topFunction()" class="btn btn-dark btn-icon" id="back-to-top">
    <i class="bi bi-caret-up fs-3xl"></i>
</button>
<!--end back-to-top-->

<!--preloader-->
<div id="preloader">
    <div id="status">
        <div class="spinner-border text-primary avatar-sm" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
</div>

<div class="customizer-setting d-none d-md-block">
    <div class="btn btn-info p-2 text-uppercase rounded-end-0 shadow-lg" (click)="openEnd()">
        <i class="bi bi-gear mb-1"></i> Customizer
    </div>
</div>

<!-- Theme Settings -->

<div class="custom-offcanvas righsidebar">
    <div class="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header">
        <div class="me-2">
            <h5 class="mb-1 text-white">RMS Builder</h5>
            <p class="text-white text-opacity-75 mb-0">Choose your themes &amp; layouts etc.</p>
        </div>

        <button type="button" class="btn-close btn-close-white ms-auto" id="customizerclose-btn" (click)="closeoffcanvas()" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body">
        <div>
            <h6 class="fs-md mb-1">Layout</h6>
            <p class="text-muted fs-sm">Choose your layout</p>

            <div class="row">
                <div class="col-4">
                    <div class="form-check card-radio">
                        <input id="customizer-layout01" name="data-layout" type="radio" value="vertical" class="form-check-input" (click)="changeLayout('vertical')">
                        <label class="form-check-label p-0 avatar-md w-100" for="customizer-layout01">
                            <span class="d-flex gap-1 h-100">
                                <span class="flex-shrink-0">
                                    <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                        <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    </span>
                                </span>
                                <span class="flex-grow-1">
                                    <span class="d-flex h-100 flex-column">
                                        <span class="bg-light d-block p-1"></span>
                                        <span class="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Vertical</h5>
                </div>
                <div class="col-4">
                    <div class="form-check card-radio">
                        <input id="customizer-layout02" name="data-layout" type="radio" value="horizontal" class="form-check-input" (click)="changeLayout('horizontal')">
                        <label class="form-check-label p-0 avatar-md w-100" for="customizer-layout02">
                            <span class="d-flex h-100 flex-column gap-1">
                                <span class="bg-light d-flex p-1 gap-1 align-items-center">
                                    <span class="d-block p-1 bg-primary-subtle rounded me-1"></span>
                                    <span class="d-block p-1 pb-0 px-2 bg-primary-subtle ms-auto"></span>
                                    <span class="d-block p-1 pb-0 px-2 bg-primary-subtle"></span>
                                </span>
                                <span class="bg-light d-block p-1"></span>
                                <span class="bg-light d-block p-1 mt-auto"></span>
                            </span>
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Horizontal</h5>
                </div>
                <div class="col-4">
                    <div class="form-check card-radio">
                        <input id="customizer-layout03" name="data-layout" type="radio" value="twocolumn" class="form-check-input" (click)="changeLayout('twocolumn')">
                        <label class="form-check-label p-0 avatar-md w-100" for="customizer-layout03">
                            <span class="d-flex gap-1 h-100">
                                <span class="flex-shrink-0">
                                    <span class="bg-light d-flex h-100 flex-column gap-1">
                                        <span class="d-block p-1 bg-primary-subtle mb-2"></span>
                                        <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                    </span>
                                </span>
                                <span class="flex-shrink-0">
                                    <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    </span>
                                </span>
                                <span class="flex-grow-1">
                                    <span class="d-flex h-100 flex-column">
                                        <span class="bg-light d-block p-1"></span>
                                        <span class="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Two Column</h5>
                </div>
                <!-- end col -->
            </div>

            <h6 class="mt-4 fs-md mb-1">Theme</h6>
            <p class="text-muted fs-sm">Choose your suitable Theme.</p>

            <div class="row">
                <div class="col-6">
                    <div class="form-check card-radio">
                        <input id="customizer-theme01" name="data-theme" type="radio" value="default" class="form-check-input" [checked]="layoutTheme === 'default'" (click)="changeTheme('default')">
                        <label class="form-check-label p-0" for="customizer-theme01">
                            <img src="assets/images/custom-theme/light-mode.png" alt="" class="img-fluid">
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Default</h5>
                </div>
                <div class="col-6">
                    <div class="form-check card-radio">
                        <input id="customizer-theme02" name="data-theme" type="radio" value="material" class="form-check-input" [checked]="layoutTheme === 'material'" (click)="changeTheme('material')">
                        <label class="form-check-label p-0" for="customizer-theme02">
                            <img src="assets/images/custom-theme/material.png" alt="" class="img-fluid">
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Material</h5>
                </div>
                <div class="col-6">
                    <div class="form-check card-radio">
                        <input id="customizer-theme03" name="data-theme" type="radio" value="creative" class="form-check-input" [checked]="layoutTheme === 'creative'" (click)="changeTheme('creative')">
                        <label class="form-check-label p-0" for="customizer-theme03">
                            <img src="assets/images/custom-theme/creative.png" alt="" class="img-fluid">
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Creative</h5>
                </div>
                <div class="col-6">
                    <div class="form-check card-radio">
                        <input id="customizer-theme04" name="data-theme" type="radio" value="minimal" class="form-check-input" [checked]="layoutTheme === 'minimal'" (click)="changeTheme('minimal')">
                        <label class="form-check-label p-0" for="customizer-theme04">
                            <img src="assets/images/custom-theme/minimal.png" alt="" class="img-fluid">
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Minimal</h5>
                </div>
                <div class="col-6">
                    <div class="form-check card-radio">
                        <input id="customizer-theme05" name="data-theme" type="radio" value="modern" class="form-check-input" [checked]="layoutTheme === 'modern'" (click)="changeTheme('modern')">
                        <label class="form-check-label p-0" for="customizer-theme05">
                            <img src="assets/images/custom-theme/modern.png" alt="" class="img-fluid">
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Modern</h5>
                </div>
                <div class="col-6">
                    <div class="form-check card-radio">
                        <input id="customizer-theme06" name="data-theme" type="radio" value="interaction" class="form-check-input" [checked]="layoutTheme === 'interaction'" (click)="changeTheme('interaction')">
                        <label class="form-check-label p-0" for="customizer-theme06">
                            <img src="assets/images/custom-theme/interaction.png" alt="" class="img-fluid">
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Interaction</h5>
                </div>
            </div>

            <h6 class="mt-4 fs-md mb-1">Color Scheme</h6>
            <p class="text-muted fs-sm">Choose Light or Dark Scheme.</p>

            <div class="colorscheme-cardradio">
                <div class="row g-3">
                    <div class="col-6">
                        <div class="form-check card-radio">
                            <input class="form-check-input" type="radio" name="data-bs-theme" id="layout-mode-light" value="light" [checked]="layoutmode === 'light'" (change)="changeMode('light')">
                            <label class="form-check-label p-0 bg-transparent" for="layout-mode-light">
                                <img src="assets/images/custom-theme/light-mode.png" alt="" class="img-fluid">
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Light</h5>
                    </div>

                    <div class="col-6">
                        <div class="form-check card-radio dark">
                            <input class="form-check-input" type="radio" name="data-bs-theme" id="layout-mode-dark" value="dark" [checked]="layoutmode === 'dark'" (change)="changeMode('dark')">
                            <label class="form-check-label p-0 bg-transparent" for="layout-mode-dark">
                                <img src="assets/images/custom-theme/dark-mode.png" alt="" class="img-fluid">
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Dark</h5>
                    </div>

                    <div class="col-6">
                        <div class="form-check card-radio brand">
                            <input class="form-check-input" type="radio" name="data-bs-theme" id="layout-mode-brand" value="brand" disabled>
                            <h6 class="text-danger fs-18 lh-base text-center position-absolute top-50 start-50 translate-middle z-1 mb-0">
                                Coming Soon</h6>
                            <label class="form-check-label cursor-none p-0 bg-transparent opacity-75" for="layout-mode-brand">
                                <img src="assets/images/custom-theme/brand-mode.png" alt="" class="img-fluid">
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Brand</h5>
                    </div>
                </div>
            </div>

            @if (layout === 'vertical' || layout === 'horizontal') {
            <div id="layout-width">
                <h6 class="mt-4 fs-md mb-1">Layout Width</h6>
                <p class="text-muted fs-sm">Choose Fluid or Boxed layout.</p>

                <div class="row">
                    <div class="col-4">
                        <div class="form-check card-radio">
                            <input class="form-check-input" type="radio" name="data-layout-width" id="layout-width-fluid" value="fluid" [checked]="layoutWidth === 'fluid'" (change)="changeWidth('fluid','lg')">
                            <label class="form-check-label p-0 avatar-md w-100" for="layout-width-fluid">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                            <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Fluid</h5>
                    </div>
                    <div class="col-4">
                        <div class="form-check card-radio">
                            <input class="form-check-input" type="radio" name="data-layout-width" id="layout-width-boxed" value="boxed" [checked]="layoutWidth === 'boxed'" (change)="changeWidth('boxed','sm-hover')">
                            <label class="form-check-label p-0 avatar-md w-100 px-2" for="layout-width-boxed">
                                <span class="d-flex gap-1 h-100 border-start border-end">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                            <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Boxed</h5>
                    </div>
                </div>
            </div>
            }

            @if (layout === 'vertical' || layout === 'horizontal') {
            <div id="layout-position">
                <h6 class="mt-4 fs-md mb-1">Layout Position</h6>
                <p class="text-muted fs-sm">Choose Fixed or Scrollable Layout Position.</p>

                <div class="btn-group radio" role="group">
                    <input type="radio" class="btn-check" name="data-layout-position" id="layout-position-fixed" value="fixed" [checked]="layoutposition === 'fixed'" (change)="changePosition('fixed')">
                    <label class="btn btn-light w-sm" for="layout-position-fixed">Fixed</label>

                    <input type="radio" class="btn-check" name="data-layout-position" id="layout-position-scrollable" value="scrollable" [checked]="layoutposition === 'scrollable'" (change)="changePosition('scrollable')">
                    <label class="btn btn-light w-sm ms-0" for="layout-position-scrollable">Scrollable</label>
                </div>
            </div>
            }

            <h6 class="mt-4 fs-md mb-1">Topbar Color</h6>
            <p class="text-muted fs-sm">Choose Light or Dark Topbar Color.</p>

            <div class="row">
                <div class="col-4">
                    <div class="form-check card-radio">
                        <input class="form-check-input" type="radio" name="data-topbar" id="topbar-color-light" value="light" [checked]="topbar === 'light'" (change)="changeTopColor('light')">
                        <label class="form-check-label p-0 avatar-md w-100" for="topbar-color-light">
                            <span class="d-flex gap-1 h-100">
                                <span class="flex-shrink-0">
                                    <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                        <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    </span>
                                </span>
                                <span class="flex-grow-1">
                                    <span class="d-flex h-100 flex-column">
                                        <span class="bg-light d-block p-1"></span>
                                        <span class="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Light</h5>
                </div>
                <div class="col-4">
                    <div class="form-check card-radio">
                        <input class="form-check-input" type="radio" name="data-topbar" id="topbar-color-dark" value="dark" [checked]="topbar === 'dark'" (change)="changeTopColor('dark')">
                        <label class="form-check-label p-0 avatar-md w-100" for="topbar-color-dark">
                            <span class="d-flex gap-1 h-100">
                                <span class="flex-shrink-0">
                                    <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                        <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    </span>
                                </span>
                                <span class="flex-grow-1">
                                    <span class="d-flex h-100 flex-column">
                                        <span class="bg-primary d-block p-1"></span>
                                        <span class="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Dark</h5>
                </div>
            </div>

            @if (layout === 'vertical') {
            <div id="sidebar-size">
                <h6 class="mt-4 fs-md mb-1">Sidebar Size</h6>
                <p class="text-muted fs-sm">Choose a size of Sidebar.</p>

                <div class="row">
                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-default" value="lg" [checked]="sidebarsize === 'lg'" (change)="changeSidebarSize('lg')">
                            <label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-default">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                            <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Default</h5>
                    </div>

                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-compact" value="md" [checked]="sidebarsize === 'md'" (change)="changeSidebarSize('md')">
                            <label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-compact">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                            <span class="d-block p-1 bg-primary-subtle rounded mb-2"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Compact</h5>
                    </div>

                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-small" value="sm" [checked]="sidebarsize === 'sm'" (change)="changeSidebarSize('sm')">
                            <label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-small">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1">
                                            <span class="d-block p-1 bg-primary-subtle mb-2"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Small (Icon View)</h5>
                    </div>

                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-sidebar-size" id="sidebar-size-small-hover" value="sm-hover" [checked]="sidebarsize === 'sm-hover'" (change)="changeSidebarSize('sm-hover')">
                            <label class="form-check-label p-0 avatar-md w-100" for="sidebar-size-small-hover">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1">
                                            <span class="d-block p-1 bg-primary-subtle mb-2"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Small Hover View</h5>
                    </div>
                </div>
            </div>
            }

            @if (layout === 'vertical') {
            <div id="sidebar-view">
                <h6 class="mt-4 fs-md mb-1">Sidebar View</h6>
                <p class="text-muted fs-sm">Choose Default or Detached Sidebar view.</p>

                <div class="row">
                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-layout-style" id="sidebar-view-default" value="default" [checked]="sidebarViews === 'default'" (change)="changeSidebar('default')">
                            <label class="form-check-label p-0 avatar-md w-100" for="sidebar-view-default">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                            <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Default</h5>
                    </div>
                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-layout-style" id="sidebar-view-detached" value="detached" [checked]="sidebarViews === 'detached'" (change)="changeSidebar('detached')">
                            <label class="form-check-label p-0 avatar-md w-100" for="sidebar-view-detached">
                                <span class="d-flex h-100 flex-column">
                                    <span class="bg-light d-flex p-1 gap-1 align-items-center px-2">
                                        <span class="d-block p-1 bg-primary-subtle rounded me-1"></span>
                                        <span class="d-block p-1 pb-0 px-2 bg-primary-subtle ms-auto"></span>
                                        <span class="d-block p-1 pb-0 px-2 bg-primary-subtle"></span>
                                    </span>
                                    <span class="d-flex gap-1 h-100 p-1 px-2">
                                        <span class="flex-shrink-0">
                                            <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                                <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                                <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            </span>
                                        </span>
                                    </span>
                                    <span class="bg-light d-block p-1 mt-auto px-2"></span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Detached</h5>
                    </div>
                </div>
            </div>
            }

            @if (layout === 'vertical' || layout === 'twocolumn') {
            <h6 class="mt-4 fs-md mb-1">Sidebar Color</h6>
            <p class="text-muted fs-sm">Choose a color of Sidebar.</p>

            <div class="row">
                <div class="col-4">
                    <div class="form-check sidebar-setting card-radio" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient.show">
                        <input class="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-light" value="light" [checked]="sidebarcolor === 'light'" (change)="changeSidebarColor('light')" (click)="removeActive()">
                        <label class="form-check-label p-0 avatar-md w-100" for="sidebar-color-light">
                            <span class="d-flex gap-1 h-100">
                                <span class="flex-shrink-0">
                                    <span class="bg-white border-end d-flex h-100 flex-column gap-1 p-1">
                                        <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                    </span>
                                </span>
                                <span class="flex-grow-1">
                                    <span class="d-flex h-100 flex-column">
                                        <span class="bg-light d-block p-1"></span>
                                        <span class="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Light</h5>
                </div>
                <div class="col-4">
                    <div class="form-check sidebar-setting card-radio" data-bs-toggle="collapse" data-bs-target="#collapseBgGradient.show">
                        <input class="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-dark" value="dark" [checked]="sidebarcolor === 'dark'" (change)="changeSidebarColor('dark')" (click)="removeActive()">
                        <label class="form-check-label p-0 avatar-md w-100" for="sidebar-color-dark">
                            <span class="d-flex gap-1 h-100">
                                <span class="flex-shrink-0">
                                    <span class="bg-primary d-flex h-100 flex-column gap-1 p-1">
                                        <span class="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                        <span class="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                    </span>
                                </span>
                                <span class="flex-grow-1">
                                    <span class="d-flex h-100 flex-column">
                                        <span class="bg-light d-block p-1"></span>
                                        <span class="bg-light d-block p-1 mt-auto"></span>
                                    </span>
                                </span>
                            </span>
                        </label>
                    </div>
                    <h5 class="fs-sm text-center fw-medium mt-2">Dark</h5>
                </div>
                <div class="col-4">
                    <button class="btn btn-link avatar-md w-100 p-0 overflow-hidden border collapsed" id="collapseBgGradient1" data-bs-target="#collapseBgGradient" type="button" (click)="addActive('gradient')">
                        <span class="d-flex gap-1 h-100">
                            <span class="flex-shrink-0">
                                <span class="bg-vertical-gradient d-flex h-100 flex-column gap-1 p-1">
                                    <span class="d-block p-1 px-2 bg-soft-light rounded mb-2"></span>
                                    <span class="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                    <span class="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                    <span class="d-block p-1 px-2 pb-0 bg-soft-light"></span>
                                </span>
                            </span>
                            <span class="flex-grow-1">
                                <span class="d-flex h-100 flex-column">
                                    <span class="bg-light d-block p-1"></span>
                                    <span class="bg-light d-block p-1 mt-auto"></span>
                                </span>
                            </span>
                        </span>
                    </button>
                    <h5 class="fs-sm text-center fw-medium mt-2">Gradient</h5>
                </div>
            </div>
            <!-- end row -->

            <div class="collapse" id="collapseBgGradient">
                <div class="d-flex gap-2 flex-wrap img-switch p-2 px-3 bg-light rounded">

                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient" value="gradient" [checked]="sidebarcolor === 'gradient'" (change)="changeSidebarColor('gradient')">
                        <label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient">
                            <span class="avatar-title rounded-circle bg-vertical-gradient"></span>
                        </label>
                    </div>
                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-2" value="gradient-2" [checked]="sidebarcolor === 'gradient-2'" (change)="changeSidebarColor('gradient-2')">
                        <label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient-2">
                            <span class="avatar-title rounded-circle bg-vertical-gradient-2"></span>
                        </label>
                    </div>
                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-3" value="gradient-3" [checked]="sidebarcolor === 'gradient-3'" (change)="changeSidebarColor('gradient-3')">
                        <label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient-3">
                            <span class="avatar-title rounded-circle bg-vertical-gradient-3"></span>
                        </label>
                    </div>
                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar" id="sidebar-color-gradient-4" value="gradient-4" [checked]="sidebarcolor === 'gradient-4'" (change)="changeSidebarColor('gradient-4')">
                        <label class="form-check-label p-0 avatar-xs rounded-circle" for="sidebar-color-gradient-4">
                            <span class="avatar-title rounded-circle bg-vertical-gradient-4"></span>
                        </label>
                    </div>
                </div>
            </div>
            }

            @if (layout === 'vertical' || layout === 'twocolumn') {
            <div id="sidebar-img">
                <h6 class="mt-4 fw-semibold fs-base">Sidebar Images</h6>
                <p class="text-muted fs-sm">Choose a image of Sidebar.</p>

                <div class="d-flex gap-2 flex-wrap img-switch">
                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-none" value="none" [checked]="sidebarimage === 'none'" (change)="changeSidebarImage('none')">
                        <label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-none">
                            <span class="avatar-md w-auto bg-light d-flex align-items-center justify-content-center">
                                <i class="ri-close-fill fs-3xl"></i>
                            </span>
                        </label>
                    </div>

                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-01" value="img-1" [checked]="sidebarimage === 'img-1'" (change)="changeSidebarImage('img-1')">
                        <label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-01">
                            <img src="assets/images/sidebar/img-1.jpg" alt="" class="avatar-md w-auto object-cover">
                        </label>
                    </div>

                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-02" value="img-2" [checked]="sidebarimage === 'img-2'" (change)="changeSidebarImage('img-2')">
                        <label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-02">
                            <img src="assets/images/sidebar/img-2.jpg" alt="" class="avatar-md w-auto object-cover">
                        </label>
                    </div>
                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-03" value="img-3" [checked]="sidebarimage === 'img-3'" (change)="changeSidebarImage('img-3')">
                        <label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-03">
                            <img src="assets/images/sidebar/img-3.jpg" alt="" class="avatar-md w-auto object-cover">
                        </label>
                    </div>
                    <div class="form-check sidebar-setting card-radio">
                        <input class="form-check-input" type="radio" name="data-sidebar-image" id="sidebarimg-04" value="img-4" [checked]="sidebarimage === 'img-4'" (change)="changeSidebarImage('img-4')">
                        <label class="form-check-label p-0 avatar-sm h-auto" for="sidebarimg-04">
                            <img src="assets/images/sidebar/img-4.jpg" alt="" class="avatar-md w-auto object-cover">
                        </label>
                    </div>
                </div>
            </div>
            }
            <div id="preloader-menu">
                <h6 class="mt-4 fw-semibold fs-base">Preloader</h6>
                <p class="text-muted fs-sm">Choose a preloader.</p>

                <div class="row">
                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-preloader" id="preloader-view-custom" value="enable" [checked]="preloader === 'enable'" (change)="changeLoader('enable')">
                            <label class="form-check-label p-0 avatar-md w-100" for="preloader-view-custom">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                            <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                                <!-- <div id="preloader"> -->
                                <span class="d-flex align-items-center justify-content-center">
                                    <span class="spinner-border text-primary avatar-xxs m-auto" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </span>
                                </span>
                                <!-- </div> -->
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Enable</h5>
                    </div>
                    <div class="col-4">
                        <div class="form-check sidebar-setting card-radio">
                            <input class="form-check-input" type="radio" name="data-preloader" id="preloader-view-none" value="disable" [checked]="preloader === 'disable'" (change)="changeLoader('disable')">
                            <label class="form-check-label p-0 avatar-md w-100" for="preloader-view-none">
                                <span class="d-flex gap-1 h-100">
                                    <span class="flex-shrink-0">
                                        <span class="bg-light d-flex h-100 flex-column gap-1 p-1">
                                            <span class="d-block p-1 px-2 bg-primary-subtle rounded mb-2"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                            <span class="d-block p-1 px-2 pb-0 bg-primary-subtle"></span>
                                        </span>
                                    </span>
                                    <span class="flex-grow-1">
                                        <span class="d-flex h-100 flex-column">
                                            <span class="bg-light d-block p-1"></span>
                                            <span class="bg-light d-block p-1 mt-auto"></span>
                                        </span>
                                    </span>
                                </span>
                            </label>
                        </div>
                        <h5 class="fs-sm text-center fw-medium mt-2">Disable</h5>
                    </div>
                </div>

            </div><!-- end preloader-menu -->
        </div>
    </div>
</div>
<!-- </div>s -->
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
<div class="offcanvas-backdrop fade backdrop2 hidden" id="backdrop" (click)="closeoffcanvas()"></div>