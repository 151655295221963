const sellerOverview = [
    {
        id: '1',
        img: 'assets/images/products/img-7.png',
        name: 'Cotton Collar Tshirts For Men',
        category: 'Fashion',
        stock: '12',
        price: '$180.00',
        orders: '10',
        rating: '4.0',
        publishedDate: '15 May, 2021',
    },
    {
        id: '2',
        img: 'assets/images/products/img-4.png',
        name: 'Super Bass Blutooth Wireless Headphone',
        category: 'Headphones',
        stock: '23',
        price: '$180.00',
        orders: '19',
        rating: '4.1',
        publishedDate: '19 Apr, 2021',
    }, {
        id: '3',
        img: 'assets/images/products/img-12.png',
        name: 'MAG Back To The Future Shoes',
        category: 'Footwear',
        stock: '06',
        price: '$274.99	',
        orders: '06',
        rating: '4.2',
        publishedDate: '06 Jan, 2021',
    }, {
        id: '4',
        img: 'assets/images/products/img-11.png',
        name: 'Jeans Blue Men Boxer',
        category: 'Fashion',
        stock: '50',
        price: '$97.99',
        orders: '51',
        rating: '3.9',
        publishedDate: '12 Oct, 2021',
    },
    {
        id: '5',
        img: 'assets/images/products/img-9.png',
        name: 'Flip-Flops And House Slippers',
        category: 'Footwear',
        stock: '30',
        price: '$150.00	',
        orders: '10',
        rating: '4.2',
        publishedDate: '06 Jan, 2023',
    },
    {
        id: '6',
        img: 'assets/images/products/img-10.png',
        name: 'Men`s Round Neck Half Sleeves Solid T-Shirt',
        category: 'Fashion',
        stock: '21',
        price: '$160.00	',
        orders: '08',
        rating: ' 4.4',
        publishedDate: '15 Jan, 2021',
    },
    {
        id: '7',
        img: 'assets/images/products/img-8.png',
        name: 'Full Sleeve Solid Men Sweatshirt/Hoody',
        category: 'Fashion',
        stock: '20',
        price: '$260.00	',
        orders: '20',
        rating: '4.1',
        publishedDate: '21 Jun, 2021',
    },
    {
        id: '8',
        img: 'assets/images/products/img-7.png',
        name: 'Cotton Collar Tshirts For Men',
        category: 'Fashion',
        stock: '12',
        price: '$180.00	',
        orders: '10',
        rating: '4.0',
        publishedDate: '15 May, 2021',
    },
    {
        id: '9',
        img: 'assets/images/products/img-6.png',
        name: 'Blive Printed Men Round Neck',
        category: 'Fashion',
        stock: '35',
        price: '$250.00',
        orders: '02',
        rating: ' 3.8',
        publishedDate: '12 Oct, 2021',
    },
    {
        id: '10',
        img: 'assets/images/products/img-5.png',
        name: 'Willage Volleyball Ball Size 4',
        category: 'Sports',
        stock: '30',
        price: '$99.00',
        orders: '21',
        rating: '4.0',
        publishedDate: '30 Mar, 2021',
    },
    {
        id: '11',
        img: 'assets/images/products/img-4.png',
        name: 'Super Bass Blutooth Wireless Headphone',
        category: 'Headphones',
        stock: '23',
        price: '$180.00	',
        orders: '19',
        rating: '4.1',
        publishedDate: '19 Apr, 2021',
    },
    {
        id: '12',
        img: 'assets/images/products/img-3.png',
        name: 'Fossil Gen 5E Smart Watch',
        category: '	Watches',
        stock: '19',
        price: '$120.00 ',
        orders: '21',
        rating: '4.3',
        publishedDate: '26 Mar, 2021',
    },
    {
        id: '13',
        img: 'assets/images/products/img-2.png',
        name: 'Like Style Travel Black Handbag ',
        category: 'Luggage',
        stock: '06',
        price: '$160.00',
        orders: '02',
        rating: '4.3',
        publishedDate: '06 Jan, 2023',
    },
    {
        id: '14',
        img: 'assets/images/products/32/img-1.png',
        name: 'Cotton Collar Tshirts For Men',
        category: 'Fashion',
        stock: '12',
        price: '$215.00	',
        orders: '06',
        rating: '4.9',
        publishedDate: '12 Oct, 2022',
    }
]


const sellerList = [
    {}
]

export { sellerOverview, sellerList }