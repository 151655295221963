<section class="main_section">
    <div class="mainInner">
        <div class="inner">
            <img src="../../../assets/images/logo-light.png" alt="">
        </div>
        <div class="clock-container">
            <div class="time">{{ currentTime }}</div>
            <div class="date">{{ currentDate }}</div>
        </div>
    </div>
</section>