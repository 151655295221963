import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';

import { DashboardService } from '../../core/services/dashboard.service';
import { takeUntil } from 'rxjs';
import { BaseComponent } from 'src/app/base/base/base.component';
import { TokenAuthService } from 'src/app/core/services/tokenauth.service';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent extends BaseComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;
  houseOrders: any;
  chartOptionsPie: Highcharts.Options;

  maintenanceOrders: any;
  chartMaintenanceOptionsPie: Highcharts.Options;

  dashboardData: any;

  // User
  currentUser: any;

  constructor(
    public translate: TranslateService,
    private dashboardService: DashboardService,
    private tokenAuthService: TokenAuthService
  ) { super() }

  ngOnInit() {
    this.currentUser = this.tokenAuthService.currentUserValue();
    this.getHousingOrders();
    this.getMaintenanceOrders();
    this.getDashboardData();
  }

  getHousingOrders() {
    this.dashboardService.getDashBoard('DashBoardHouseorderStatistic').pipe(takeUntil(this.ngUnsubscribe)).subscribe((data: any) => {
      this.houseOrders = data.result;

      // Initialize the chartOptionsPie after receiving the data
      this.chartOptionsPie = {
        chart: {
          type: 'pie',
          backgroundColor: 'transparent'
        },
        title: {
          text: '<div><span>طلبات الاسكان</span></div>',
          align: 'right',
          style: {
            fontFamily: 'Dubai'
          }
        },
        series: [{
          type: 'pie',
          name: 'Brands',
          data: [
            { name: 'طلبات منفذة', y: this.houseOrders[0].doneOrderCount, color: '#00A46B' },
            { name: 'طلبات غير منفذة', y: this.houseOrders[0].notDoneOrder, color: '#f7464a' },
          ]
        }],
        plotOptions: {
          pie: {
            showInLegend: false,
            dataLabels: {
              enabled: false
            }
          }
        }
      };
    });
  }


  getMaintenanceOrders() {
    this.dashboardService.getDashBoard('DashBoardMaintaorderStatistic').pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.maintenanceOrders = data.result;

        // Initialize the chartOptionsPie after receiving the data
        this.chartMaintenanceOptionsPie = {
          chart: {
            type: 'pie',
            backgroundColor: 'transparent'
          },
          title: {
            text: '<div><span>طلبات الصيانه</span></div>',
            align: 'right',
            style: {
              fontFamily: 'Dubai'
            }
          },
          series: [{
            type: 'pie',
            name: 'Brands',
            data: [
              { name: 'طلبات منفذة', y: this.maintenanceOrders[0].doneOrderCount, color: '#00A46B' },
              { name: 'طلبات غير منفذة', y: this.maintenanceOrders[0].notDoneOrder, color: '#f7464a' },
            ]
          }],
          plotOptions: {
            pie: {
              showInLegend: false,
              dataLabels: {
                enabled: false
              }
            }
          }
        };
      }
    )
  }

  getDashboardData() {
    this.dashboardService.getDashBoardData(this.currentUser.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        this.dashboardData = data.result;
      }
    )
  }
}