import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from 'src/app/base/base/base.component';
import { takeUntil } from 'rxjs';
import { Router } from '@angular/router';

/* Models */
import { Notification } from '../../../core/models/notification';

/* Services */
import { ExportService } from '../../../core/services/exportexcel.service';
import { PaginationService } from '../../../core/services/pagination.service';
import { TokenAuthService } from '../../../core/services/tokenauth.service';
import { NotificationService } from '../../../core/services/Notification.service';

/* Data */
import { HijriDays, HijriMonths, HijriYears } from '../../../../assets/data/hijri-data';


@Component({
  selector: 'app-all-notifications',
  templateUrl: './all-notifications.component.html',
  styleUrl: './all-notifications.component.scss',
  animations: [
    trigger('expandCollapse', [
      state('expanded', style({
        height: '*',
        opacity: 1,
        padding: '*'
      })),
      state('collapsed', style({
        height: '0px',
        opacity: 0,
        padding: '0px 0px'
      })),
      transition('expanded <=> collapsed', [
        animate('300ms ease-in-out')
      ])
    ]),
    trigger('itemMove', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0, transform: 'translateY(20px)' }))
      ])
    ]),
    trigger('dragOver', [
      state('dragging', style({ transform: 'scale(1.05)', backgroundColor: '#ccffd7' })),
      state('notDragging', style({ transform: 'scale(1)', backgroundColor: '#FFF' })),
      transition('notDragging <=> dragging', [
        animate('200ms ease-in-out')
      ])
    ])
  ]
})
export class AllNotificationsComponent extends BaseComponent implements OnInit {

  // User
  currentUser: any;

  // Hijri Date
  hijriDays = HijriDays;
  hijriMonths = HijriMonths;
  hijriYears = HijriYears;

  // Popup Models & Msgs
  SucessModel: boolean = false;
  ErrorModel: boolean = false;
  SucessMsg: string = '';
  ErrorMsg: string = '';

  // Notifications
  Notifications = [] as Notification[];
  noNotifications: boolean = true;
  itemsNotificationsPerPage = 10;
  totalNotificationsItems = this.Notifications.length;
  pagedNotificationsData: Notification[] = [];
  activeNotificationsRow: number | null = null;

  isExpanded: boolean = true;
  sortDirection: { [key in keyof Notification]?: boolean } = {};


  constructor(
    private exportService: ExportService,
    private paginationService: PaginationService,
    private tokenAuthService: TokenAuthService,
    private notificationService: NotificationService,
    private router: Router,
  ) { super(); }


  async ngOnInit(): Promise<void> {
    this.currentUser = this.tokenAuthService.currentUserValue();
    await this.getNotifications();
    this.setPage(1);
  }


  // Notifications
  private getNotifications(): Promise<void> {
    return new Promise((resolve) => {
      this.notificationService.getAllCurrentNotificationForUser(this.currentUser.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
        (data: any) => {
          this.Notifications = data.result;
          this.noNotifications = this.Notifications.length === 0;
          this.totalNotificationsItems = this.Notifications.length;
          resolve();
        }
      );
    });
  }


  setPage(page: number) {
    this.pagedNotificationsData = this.paginationService.getPaginatedData(this.Notifications, page, this.itemsNotificationsPerPage);
  }


  sortTable(column: keyof Notification) {
    const direction = this.sortDirection[column] ? 1 : -1;
    this.pagedNotificationsData.sort((a, b) => {
      const aValue = a[column];
      const bValue = b[column];

      if (aValue < bValue) {
        return -1 * direction;
      } else if (aValue > bValue) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
    this.sortDirection[column] = !this.sortDirection[column];
  }


  exportNotificationsToExcel(): void {
    this.exportService.exportToExcel(this.Notifications, 'الإشعارات');
  }

  toggleExpandCollapse() {
    this.isExpanded = !this.isExpanded;
  }
}
