import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { Subject, Subscription } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { MainDashboardComponent } from 'src/app/pages/main-dashboard/main-dashboard.component';
import { PagesRoutingModule } from 'src/app/pages/pages-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';

interface Event {
    type: string;
    payload?: any;
}

type EventCallback = (payload: any) => void;

@Injectable({
    providedIn: 'root'
})
export class EventService {

    private handler = new Subject<Event>();
    constructor() { }

    /**
     * Broadcast the event
     * @param type type of event
     * @param payload payload
     */
    broadcast(type: string, payload = {}) {
        this.handler.next({ type, payload });
    }

    /**
     * Subscribe to event
     * @param type type of event
     * @param callback call back function
     */
    subscribe(type: string, callback: EventCallback): Subscription {
        return this.handler.pipe(
            filter(event => event.type === type)).pipe(
                map(event => event.payload))
            .subscribe(callback);
    }
}

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    TranslateModule,
    TabsModule,
    NgSelectModule,
    BsDatepickerModule,
    HighchartsChartModule,
    PagesRoutingModule,
    SharedModule
  ]
})
export class PagesModule {
  static components: any;
}
