import { MenuItem } from "./menu.model";

export const AdminMENU: MenuItem[] = [
    {
        id: 1,
        label: 'Dashboard',
        icon: 'ph-house-line',
        link: '/dashborad',
    },
    {
        id: 2,
        label: 'companies',
        icon: 'ph-buildings',
        link: '/companies',
    },
    {
        id: 3,
        label: 'packages',
        icon: 'ph-rocket-launch',
        link: '/packages',
       
    },
    {
        id: 4,
        label: 'users',
        icon: 'ph-users',
        link: '/users',
    },
    {
        id: 5,
        label: 'rolesandpermission',
        icon: 'ph-puzzle-piece',
        link: '/permissions',
    },
    {
        id: 6,
        label: 'lists',
        icon: 'ph-stack',
        link: '/lists',
    },
    {
        id: 7,
        label: 'theReport',
        icon: 'ph-file-text',
        link: '/reports',
    },
    {
        id: 8,
        label: 'callUs',
        icon: 'ph-phone',
        link: '/contact-us',
    },
    {
        id:9 ,
        label: 'FrequentlyAskedQuestions',
        icon: 'ph-question',
        link: '/common-questions',
    }
]