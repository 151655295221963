import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { BaseService } from './base.service';
import { Role } from '../models/role';

@Injectable({
    providedIn: 'root'
})

export class RolesService {

    controller = 'Roles';
    constructor(private base: BaseService) {}

    saveRole(obj: any) {
        return this.base.save(this.controller + '/CreateRole', obj)
    }

    getRoles() {
        return this.base.gets(this.controller + '/GetAllRoles')
    }

    save(obj: Role) {
        return this.base.save(this.controller + '/CreateRole', obj)
    }

    delete(id: any) {
        return this.base.remove(this.controller + '/DeleteRole/', id)
    }

    findAllByUser(id: any) {
        return this.base.gets(this.controller + '/FindAllByUser/' + id)
    }

    getByID(id: any) {
        return this.base.gets(this.controller + '/GetByRoleId/' + id)
    }
}