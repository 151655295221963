import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ApiObjectData } from '../models/api-object-data';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  appUrl = environment.baseUrl + '/';
  apiUrl = environment.baseUrl + '/api/';
  private currentUser: any; // Property to store user data

  constructor(
    public http: HttpClient,
    public router: Router,
    public translate: TranslateService
  ) {}

  get(id: number, controller: string): Observable<ApiObjectData> {
    return this.http.get<ApiObjectData>(this.apiUrl + controller + '/' + id);
  }

  gets(controller: string): Observable<ApiObjectData> {
    return this.http.get<ApiObjectData>(this.apiUrl + controller + '/');
  }

  save(controller: string, model: any) {
    return this.http.post(this.apiUrl + controller + '/', model);
  }

  saveBatch(controller: string, model: any) {
    return this.http.post(this.apiUrl + controller + '/', model);
  }

  remove(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller +  id);
  }

  posts(controller: string, id: number) {
    return this.http.post(this.apiUrl + controller +  id,'');
  }

  Remove(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller + '/Remove/' + id);
  }

  delete(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller + '/delete/' + id);
  }

  removes(controller: string, id: number) {
    return this.http.delete(this.apiUrl + controller + id)
  }

  post(controller: string, operation: string, model: any) {
    return this.http.post(this.apiUrl + controller + '/' + operation, model);
  }

  update(controller: string, id: any, secondId: any) {
    return this.http.post(this.apiUrl + controller + '/' + id + '/' + secondId, {});
  }

  deletePost(controller: string, id: any) {
    return this.http.post(this.apiUrl + controller + '/' + id, {});
  }

  Put(controller:string, model:any)
  {
    return this.http.put(this.apiUrl + controller , model)
  }

  Putmini(controller: string, id: any, userId: any) {
    return this.http.put(this.apiUrl + controller + '/' + id + '/' + userId, {});
  }

  PutPlus(controller: string, id: any, userId: any, buildingId: any) {
    return this.http.put(this.apiUrl + controller + '/' + id + '/' + userId + '/' + buildingId, {});
  }

  // Method to set current user data
  setCurrentUser(user: any): void {
    this.currentUser = user;
    localStorage.setItem('currentUser', JSON.stringify(user)); // Store user in localStorage
  }

  // Method to get current user data
  getCurrentUser(): any {
    if (!this.currentUser) {
      const user = localStorage.getItem('currentUser');
      this.currentUser = user ? JSON.parse(user) : null;
    }
    return this.currentUser;
  }
}