import { Component, Output, EventEmitter, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { RootReducerState } from 'src/app/store';
import { Store } from '@ngrx/store';
import { changeMode } from 'src/app/store/layouts/layout-action';
import { getLayoutmode } from 'src/app/store/layouts/layout-selector';
import { BaseComponent } from 'src/app/base/base/base.component';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';

/* Models */
import { User } from '../../core/models/user';

/* Services */
import { LanguageService } from 'src/app/core/services/language.service';
import { BaseService } from 'src/app/core/services/base.service';
import { TokenAuthService } from 'src/app/core/services/tokenauth.service';
import { UserService } from 'src/app/core/services/user.service';
import { TasksService } from 'src/app/core/services/tasks.service';
import { NotificationService } from 'src/app/core/services/Notification.service';
import { HubService } from 'src/app/core/services/hub.service';




@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {

  user = {} as User;

  country: any;
  selectedItem!: any;

  flagvalue: any;
  valueset: any;
  countryName: any;
  cookieValue: any;
  userData: any;
  cartData: any;

  element: any;
  mode: string | undefined;


  userId:any;
  tasks:{ name: string, description: string,dataURL:string,excutedProcessID:number,recordID:number,createDate: string }[] = [];
  task:{name:string,description:string,dataURL:string,excutedProcessID:number,recordID:number,createDate: string }= { name: '', description: '',dataURL:'',excutedProcessID:0,recordID:0, createDate: ''};

  @Output() mobileMenuButtonClicked = new EventEmitter();

  totalTasks: number = 0;

  notificationList: { name: string, description: string,dataURL:string,excutedProcessID:number,recordID:number,createDate: string }[] = [];
  notification:{name:string,description:string,dataURL:string,excutedProcessID:number,recordID:number,createDate: string }= { name: '', description: '',dataURL:'',excutedProcessID:0,recordID:0, createDate: ''}

  totalNotify: number = 0;
  private ngUnsubscribe = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    public languageService: LanguageService,
    private router: Router,
    public store: Store<RootReducerState>,
    public baseService: BaseService,
    private tokenAuthService: TokenAuthService,
    private userService: UserService,
    private tasksService:TasksService,
    private notificationService: NotificationService,
    public hubService:HubService
   ) 
    
    {}

  ngOnInit(): void {
    this.element = document.documentElement;

    const currentUser = this.tokenAuthService.currentUserValue();
    this.userId = currentUser.userId; 
    this.getAllTasks();
    this.getAllNotifications();
    this.hubService.taskUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedTask) => {
      this.updateTaskList(updatedTask);
    });

    this.hubService.notificationUpdated$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((updatedNotification) => {
      this.updateNotificationList(updatedNotification);
    })

    this.userService.getUserData(currentUser.userId).subscribe(
      (data: any) => {
        this.user = data.result.returnData;
      }
    );

    this.hubService.startConnection(this.userId);
    var x = this.hubService.updateRequestNotificationDataListener();
    var y = this.hubService.updateNotificationDataListener();
  }
  
  updateNotificationList(notificationArray: any[]) {
    if (notificationArray && notificationArray.length > 0) {
      const lastNotification = notificationArray[notificationArray.length - 1];  // Get the last task in the array
      this.notification = { name: '', description: '' , dataURL:'', excutedProcessID:0,recordID:0, createDate: ''};
      this.notification.description = lastNotification.describtion;
      this.notification.name = lastNotification.name;
      this.notification.dataURL = lastNotification.dataURL;
      this.notification.excutedProcessID = lastNotification.excutedProcessID;
      this.notification.recordID = lastNotification.recordID;
      this.notification.createDate = lastNotification.createDate;
      this.notificationList.push(this.notification);  // Push only the last task
      this.totalNotify = this.notificationList.length;
    }
  }

  updateTaskList(taskArray: any[]) {
    if (taskArray && taskArray.length > 0) {
      const lastTask = taskArray[taskArray.length - 1];  // Get the last task in the array
      this.task = { name: '', description: '' , dataURL:'', excutedProcessID:0,recordID:0, createDate: ''};
      this.task.description = lastTask.describtion;
      this.task.name = lastTask.name;
      this.task.dataURL = lastTask.dataURL;
      this.task.excutedProcessID = lastTask.excutedProcessID;
      this.task.recordID = lastTask.recordID;
      this.task.createDate = lastTask.createDate;
      this.tasks.push(this.task);  // Push only the last task
      this.totalTasks = this.tasks.length;
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  navigateWithData(task:any) {
    if (task.name === 'أمر إسكان') {
      this.router.navigate(['/tasks/deserve-house'], { state: { task } });
    } 

    if (task.name === 'طلب السكن' || task.name === 'طلب إسكان سائق') {
      this.router.navigate(['/tasks/housingrquest-approval'], { state: { task } });
    }
    if (task.name === ' إضافة قطع غيار') {
      this.router.navigate(['/tasks/revision-workshop'], { state: { task } });
    }

    if (task.name === 'أمر إخلاء') {
      this.router.navigate(['/tasks/house-leaving-order'], { state: { task } });
    }
    if (task.name === 'إلغاء تسديد الساكن') {
      this.router.navigate(['/tasks/cancelpay-approval'], { state: { task } });
    }
    if (task.name === 'طلب إخلاء طرف') {
      this.router.navigate(['/tasks/dischargeorder-approval'], { state: { task } });
    }
    if (task.name === 'طلب إيقاف خدمات عن ساكن') {
      this.router.navigate(['/tasks/penalty-approval'], { state: { task } });
    }
    if (task.name === 'تمديد عقد شركة') {
      this.router.navigate(['/tasks/extend-company-contract'], { state: { task } });
    }

    if (task.name === 'إضافة عقد خدمات إستثمارية') {
      this.router.navigate(['/tasks/service-contract'], { state: { task } });
    }

    if (task.name === 'كفيل جديد') {
      this.router.navigate(['/tasks/newKafeel'], { state: { task } });
    }
    
    if (task.name === 'طلب مد فترة الإخلاء') {
      this.router.navigate(['/tasks/newKafeel'], { state: { task } });
    }
  }

  getAllNotifications() {
    this.notificationService.getAllCurrentNotificationForUser(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => {
        for(let i = 0; i < data.result.length; i++)
          {
            this.notification = { name: '', description: '' , dataURL:'', excutedProcessID:0,recordID:0, createDate: ''};
            this.notification.description = data.result[i].describtion;
            this.notification.name = data.result[i].name;
            this.notification.dataURL = data.result[i].dataURL;
            this.notification.excutedProcessID = data.result[i].excutedProcessID;
            this.notification.recordID = data.result[i].recordID;
            this.notification.createDate = data.result[i].createDate;
            this.notificationList.push(this.notification);
            this.totalNotify = data.result.length;
          }
      }
    )
  }

  getAllTasks()
  {
    this.tasksService.getTaskById(this.userId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(
      (data: any) => 
      {
        for(let i = 0; i < data.result.length; i++)
          {
            this.task = { name: '', description: '' , dataURL:'', excutedProcessID:0,recordID:0, createDate: ''};
            this.task.description = data.result[i].describtion;
            this.task.name = data.result[i].name;
            this.task.dataURL = data.result[i].dataURL;
            this.task.excutedProcessID = data.result[i].excutedProcessID;
            this.task.recordID = data.result[i].recordID;
            this.task.createDate = data.result[i].createDate;
            this.tasks.push(this.task);
            this.totalTasks = data.result.length;
          }
      },
   
    (error) => 
    {
     console.error('Failed to load tasks', error);
    });
  }




  logout() {
    this.tokenAuthService.logout();
    this.router.navigate(['/auth/login']);
  }

  /*** Topbar Light-Dark Mode Change ***/
  changeMode(mode: string) {
    this.mode = mode;
    if (mode == 'auto') {
      document.documentElement.setAttribute('data-bs-theme', 'light')
      document.documentElement.setAttribute('data-topbar', 'light');
      document.documentElement.classList.add('mode-auto')
    } else {
      this.store.dispatch(changeMode({ mode }));
    this.store.select(getLayoutmode).subscribe((mode) => {
      document.documentElement.setAttribute('data-bs-theme', mode);
    })
      document.documentElement.classList.remove('mode-auto')
      document.documentElement.setAttribute('data-topbar', mode);
    }
  }

  /**** Language Listing*/
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.svg', lang: 'en' },
    { text: 'Arabic', flag: 'assets/images/flags/ae.svg', lang: 'ar' },
  ];

  /**** Language Value Set ***/
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /*** Toggle the menu bar when having mobile screen ***/
  toggleMobileMenu(event: any) {
    document.querySelector('.hamburger-icon')?.classList.toggle('open')
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /*** Fullscreen method ***/
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /*** Logout the user ***/
}
