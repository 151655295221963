import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Component
import { MainDashboardComponent } from './main-dashboard/main-dashboard.component';
import { AllNotificationsComponent } from './notifications/all-notifications/all-notifications.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: MainDashboardComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'housing', loadChildren: ()=> import('./housing/housing.module').then(m => m.HousingModule)
  },
  {
    path: 'maintainance', loadChildren: ()=> import('./maintainance/maintainance.module').then(m => m.MaintainanceModule)
  },
  {
    path: 'companies', loadChildren: ()=> import('./companies/companies.module').then(m => m.CompaniesModule)
  },
  {
    path: 'approvals', loadChildren: ()=> import('./approvals-levels/approvals-levels.module').then(m => m.ApprovalsLevelsModule)
  },
  {
    path: 'administration', loadChildren: ()=> import('./administration/administration.module').then(m => m.AdministrationModule)
  },
  {
    path: 'warehouse', loadChildren: ()=> import('./warehouse/warehouse.module').then(m => m.WarehouseModule)
  },
  {
    path: 'tasks', loadChildren: ()=> import('./tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: 'finance', loadChildren: ()=> import('./finance/finance.module').then(m => m.FinanceModule)
  },
  {
    path: 'all-notifications',
    component: AllNotificationsComponent
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
