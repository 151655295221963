<header id="page-topbar">
    <div class="layout-width">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- LOGO -->
                <div class="navbar-brand-box horizontal-logo">
                    <a routerLink="/" class="logo logo-light">
                        <span class="logo-sm">
                            <img src="assets/images/logo-sm.png" alt="" height="22">
                        </span>
                        <span class="logo-lg">
                            <img src="assets/images/logo-light.png" alt="" height="22">
                        </span>
                    </a>
                </div>

                <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger shadow-none" id="topnav-hamburger-icon" (click)="toggleMobileMenu($event)">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

                <!-- <div class="companyDetails">
                    <img src="../../../assets/images/CLogo.png" alt="CompanyLogo">
                    <span>{{'CompanyName' | translate}}</span>
                </div> -->
            </div>

            <div class="d-flex align-items-center">
                <div class="ms-1 header-item d-none d-sm-flex">
                    <button type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" data-toggle="fullscreen" (click)="fullscreen()">
                        <i class="bi bi-arrows-fullscreen fs-lg"></i>
                    </button>
                </div>
               
                <div dropdown class="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                    <button dropdownToggle type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                        <i class='bi bi-bell fs-2xl'></i>
                        <span class="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger"><span class="notification-badge">{{totalNotify}}</span><span class="visually-hidden">unread messages</span></span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" style="border: none;">

                        <div *ngIf="totalNotify !== 0" class="dropdown-head rounded-top dropdown-body" style="max-height: 400px; overflow-y: auto;text-align: right;">
                            <div class="p-3 border-bottom border-bottom-dashed divCon"  *ngFor="let notification of notificationList">
                                <div class="taskDiv">
                                    <span class="taskTitle">{{notification.name}}</span>
                                    <span>{{notification.description}}</span>
                                    <span style="color: #a9a6a6;">من تاريخ : {{notification.createDate | dateFormat}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="totalNotify !== 0" class="notificationFooter" routerLink="/all-notifications">
                            <span>{{'showAllNotifications' | translate}}</span>
                        </div>

                        <div *ngIf="totalNotify === 0" class="dropdown-head rounded-top dropdown-body" style="max-height: 400px; overflow-y: auto;text-align: right;">
                            <div class="notificationFooter">
                                <span>{{'NoNotifications' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div dropdown class="dropdown topbar-head-dropdown ms-1 header-item" id="notificationDropdown">
                    <button dropdownToggle type="button" class="btn btn-icon btn-topbar btn-ghost-dark rounded-circle" id="page-header-notifications-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false">
                        <i class="bi bi-briefcase fs-2xl"></i>
                        <span class="position-absolute topbar-badge fs-3xs translate-middle badge rounded-pill bg-danger"><span class="notification-badge">{{totalTasks}}</span><span class="visually-hidden">unread messages</span></span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" aria-labelledby="page-header-notifications-dropdown" style="border: none;">

                        <div *ngIf="totalTasks !== 0" class="dropdown-head rounded-top dropdown-body" style="max-height: 400px; overflow-y: auto;text-align: right;">
                            <div class="p-3 border-bottom border-bottom-dashed divCon" *ngFor="let task of tasks">
                                <div (click)="navigateWithData(task)" class="taskDiv">
                                    <span class="taskTitle">{{task.name}}</span>
                                    <span>{{task.description}}</span>
                                    <span style="color: #a9a6a6;">من تاريخ : {{task.createDate | dateFormat}}</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="totalTasks !== 0" class="notificationFooter" routerLink="/tasks/tasks">
                            <span>{{'showAllTasks' | translate}}</span>
                        </div>
                        <div *ngIf="totalTasks === 0" class="dropdown-head rounded-top dropdown-body" style="max-height: 400px; overflow-y: auto;text-align: right;">
                            <div class="notificationFooter">
                                <span>{{'NoNotifications' | translate}}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div dropdown class="dropdown ms-sm-3 header-item topbar-user">
                    <button dropdownToggle type="button" class="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding-right: 0;">
                        <span class="d-flex align-items-center">
                            <img class="rounded-circle header-profile-user" src="assets/images/Avatar.png" alt="Header Avatar">
                        </span>
                    </button>
                    <div *dropdownMenu class="dropdown-menu dropdown-menu-end" style="border: none;text-align: right;">
                        <h6 class="dropdown-header">مرحباً بك {{this.user.name}}</h6>
                        <a class="dropdown-item" routerLink="/pages/profile"><i class="mdi mdi-account-circle text-muted fs-lg align-middle me-1"></i> <span class="align-middle"> الملف الشخصي</span></a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="logout()"><i class="mdi mdi-logout text-muted fs-lg align-middle me-1"></i> <span class="align-middle" data-key="t-logout"> تسجيل الخروج</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>