import { Component, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  providers: [DatePipe]
})
export class HomeComponent implements OnInit, OnDestroy {

  currentTime: string = '';
  currentDate: string = '';
  private clockInterval: any;

  // Arabic month names
  arabicMonths: string[] = [
    'يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'
  ];

  constructor(private datePipe: DatePipe) { }

  ngOnInit(): void {
    // Initialize the clock by setting an interval to update the time and date every second
    this.updateTime(); // Initial time and date setting
    this.clockInterval = setInterval(() => {
      this.updateTime();
    }, 1000); // 1 second interval
  }

  // Update both the time and the date
  updateTime() {
    const now = new Date();

    // Get the time in 12-hour format with AM/PM
    let formattedTime = this.datePipe.transform(now, 'hh:mm:ss a') ?? '';

    // Replace AM with صباحًا and PM with مساء
    if (formattedTime.includes('AM')) {
      formattedTime = formattedTime.replace('AM', 'صباحًا');
    } else if (formattedTime.includes('PM')) {
      formattedTime = formattedTime.replace('PM', 'مساءً');
    }

    // Set the formatted time
    this.currentTime = formattedTime;

    // Get the day and the year
    const day = this.datePipe.transform(now, 'd') ?? ''; // Get the day number
    const year = this.datePipe.transform(now, 'yyyy') ?? ''; // Get the year

    // Get the month and convert it to Arabic
    const monthIndex = now.getMonth(); // Get the index of the month (0-11)
    const arabicMonth = this.arabicMonths[monthIndex]; // Get the Arabic month name

    // Set the formatted date as "day ArabicMonth year"
    this.currentDate = `${day} ${arabicMonth} ${year}`;
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.clockInterval) {
      clearInterval(this.clockInterval);
    }
  }

}
