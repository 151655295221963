import { Injectable } from '@angular/core';
import  * as SignalR from '@microsoft/signalr';
//import Notiflix from 'notiflix';
import { Observable, Subject } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
//import { Request } from '../models/request';
import { BaseService } from './base.service';

@Injectable()
export class HubService {
userid:any;
username:any;
 connectionId: string;
 public data: ApiObjectData[];
constructor(public baseService:BaseService) { }
hubConnection: SignalR.HubConnection;
private taskUpdatedSubject = new Subject<any>();
taskUpdated$ = this.taskUpdatedSubject.asObservable();

private notificationUpdatedSubject = new Subject<any>();
notificationUpdated$ = this.notificationUpdatedSubject.asObservable();

public startConnection = (id:any) => {
    this.userid =id
    this.hubConnection = new SignalR.HubConnectionBuilder()
    .withUrl(this.baseService.appUrl+'notify',{
        skipNegotiation:true,
        transport: SignalR.HttpTransportType.WebSockets
      }).build();
    this.hubConnection
      .start()
      .then()
      .then(() => this.join())
      .catch()
  }

private join = () => {
    this.hubConnection.invoke('join',this.userid)
    .then((data:any) => {
      this.connectionId = data;
    });
  }

  public updateRequestNotificationDataListener = () => {
    this.hubConnection.on('updateRequestNotification', (data:any) => {
      this.data = data;
      this.taskUpdatedSubject.next(data);
    })
  }

  public updateNotificationDataListener = () => {
    this.hubConnection.on('updateNotification', (data:any) => {
      this.data = data;
      this.notificationUpdatedSubject.next(data);
    })
  }

}

