import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value) return '';

    // Parse the input date string
    const date = new Date(value);

    // Define options for formatting
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };

    // Create a formatter for Arabic locale
    const formatter = new Intl.DateTimeFormat('ar-EG', options);

    // Format the date
    return formatter.format(date);
  }

}
