import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PaginationService } from 'src/app/core/services/pagination.service';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() totalItems: number = 0;
  @Input() itemsPerPage: number = 0;
  @Output() pageChange = new EventEmitter<number>();

  pages = [] as number[];
  currentPage: number = 1;

  constructor(private paginationService: PaginationService) {}

  ngOnChanges(): void {
    this.pages = this.paginationService.calculatePages(this.totalItems, this.itemsPerPage);
  }

  changePage(page: number) {
    this.currentPage = page;
    this.pageChange.emit(this.currentPage);
  }
}