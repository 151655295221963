import { Injectable } from '@angular/core';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})

export class TasksService {

  controller = 'TbTask';
  personController = 'Person';
  approvalController = 'ExcutedApproval';
  maintenanceController = 'MaintenanceOrder';
  housingController = 'HousingRequest';
  constructor(private base: BaseService) { }

  getTaskById(id: number) {
    return this.base.get(id, this.controller + '/GetAllCurrentTaskForUser')
  }

  getInpersonForApproved(id: number) {
    return this.base.get(id, this.personController + '/GetInPersonsForApprovedDeserved')
  }

  saveExcutedApproval(approval: any) {
    return this.base.save(this.approvalController, approval)
  }

  getAllWorkShop(id: number) {
    return this.base.get(id, this.maintenanceController + '/GetAllWorkshopOrderSparpartsRevision')
  }

  getHousingRequest(id: number) {
    return this.base.get(id, this.housingController + '/getHousingOrderDetail')
  }
  getByCancelPayId(id: number) {
    return this.base.get(id, this.personController + '/GetByCancelPayID')
  }

  getDataForHousingRequest(id: number) {
    return this.base.get(id, this.housingController + '/getHousingOrderDetail')
  }

}