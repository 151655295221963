<!-- <div class="loadingScreen" *ngIf="loadingScreen">
    <div class="svg-wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" id="rms-logo" width="666" height="256" viewBox="0 0 666 256" fill="none">
            <g clip-path="url(#clip0_4002_46225)">
                <path
                    d="M158.087 0C132.078 0 107.135 10.3258 88.7436 28.7058L28.723 88.6904C10.332 107.07 0 131.999 0 157.992C0 212.121 43.906 256 98.0668 256C124.076 256 149.019 245.674 167.41 227.294L208.927 185.802C208.927 185.801 208.928 185.803 208.927 185.802L329.892 64.9097C338.676 56.1315 350.588 51.2 363.01 51.2C383.806 51.2 401.437 64.7459 407.55 83.4907L445.726 45.3373C428.311 18.0765 397.773 0 363.01 0C337.001 0 312.058 10.3258 293.667 28.7058L131.185 191.09C122.401 199.869 110.489 204.8 98.0668 204.8C72.1999 204.8 51.2308 183.844 51.2308 157.992C51.2308 145.578 56.1652 133.672 64.9487 124.894L124.969 64.9097C133.752 56.1315 145.665 51.2 158.087 51.2C178.884 51.2 196.514 64.7465 202.627 83.4919L240.804 45.3382C223.388 18.077 192.851 0 158.087 0Z"
                    fill="none" />
                <path
                    d="M336.108 191.09C327.325 199.869 315.412 204.8 302.99 204.8C282.197 204.8 264.567 191.258 258.453 172.516L220.277 210.668C237.694 237.926 268.23 256 302.99 256C328.999 256 353.943 245.674 372.334 227.294L534.815 64.9097C543.599 56.1315 555.512 51.2 567.934 51.2C593.801 51.2 614.77 72.1565 614.77 98.0079C614.77 110.422 609.835 122.328 601.052 131.106L541.031 191.09C532.248 199.869 520.335 204.8 507.913 204.8C487.118 204.8 469.488 191.255 463.374 172.512L425.199 210.665C442.615 237.925 473.152 256 507.913 256C533.922 256 558.866 245.674 577.257 227.294L637.277 167.31C655.668 148.93 666 124.001 666 98.0079C666 43.8796 622.095 0 567.934 0C541.925 0 516.981 10.3258 498.59 28.7058L336.108 191.09Z"
                    fill="none" />
            </g>
            <defs>
                <clipPath id="clip0_4002_46225">
                    <rect width="666" height="256" fill="none" />
                </clipPath>
            </defs>
        </svg>
    </div>
</div> -->
<router-outlet></router-outlet>