export const HijriDays: number[] = Array.from({ length: 30 }, (_, i) => i + 1);

export const HijriMonths: { id: number, name: string }[] = [
    { id: 1, name: 'محرم' },
    { id: 2, name: 'صفر' },
    { id: 3, name: 'ربيع أول' },
    { id: 4, name: 'ربيع الآخر' },
    { id: 5, name: 'جمادى الأولى' },
    { id: 6, name: 'جمادى الآخرة' },
    { id: 7, name: 'رجب' },
    { id: 8, name: 'شعبان' },
    { id: 9, name: 'رمضان' },
    { id: 10, name: 'شوال' },
    { id: 11, name: 'ذو القعدة' },
    { id: 12, name: 'ذو الحجة' }
];

export const HijriYears: number[] = Array.from({ length: 250 }, (_, i) => 1545 - i);