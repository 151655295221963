import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DateFormatService {
  private datePipe: DatePipe;

  private arabicDays = [
    'الأحد',
    'الاثنين',
    'الثلاثاء',
    'الأربعاء',
    'الخميس',
    'الجمعة',
    'السبت',
  ];

  private arabicMonths = [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ];

  constructor() {
    this.datePipe = new DatePipe('en-US');
  }

  getArabicFormattedDate(date: Date): string {
    const dayName = this.arabicDays[date.getDay()];
    const day = date.getDate();
    const monthName = this.arabicMonths[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${this.convertToArabicNumbers(day)} ${monthName} ${this.convertToArabicNumbers(year)}`;
  }

  private convertToArabicNumbers(number: number): string {
    const arabicNumbers = [
      '٠',
      '١',
      '٢',
      '٣',
      '٤',
      '٥',
      '٦',
      '٧',
      '٨',
      '٩',
    ];
    return number
      .toString()
      .split('')
      .map((digit) => arabicNumbers[parseInt(digit, 10)])
      .join('');
  }
}