<section class="main_section">
    <div class="breadcrumb">
        <h5 class="pagename">{{'Notifications' | translate}}</h5>
    </div>


    <div class="table_card" >
        <div class="table_card_header">
            <h6>{{'Notifications' | translate}}</h6>
            <div class="tablecardActions">
                <i class="ph-arrow-square-out actionI" title="{{'export' | translate}}" (click)="exportNotificationsToExcel()"></i>
                <i class="ph-caret-down actionI" title="{{'minimize' | translate}}" (click)="toggleExpandCollapse()"></i>
            </div>
        </div>

        <div class="table_card_body" [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
            <hr style="color: #DDD;">

            <div class="desktopGrid" *ngIf="!noNotifications">
                <table class="table ">
                    <thead class="table-light">
                        <tr>
                            <th scope="col" class="thWithFilter"><span>{{'taskName' | translate}}</span> <i class="ph-arrows-down-up" (click)="sortTable('name')"></i></th>
                            <th scope="col" class="thWithFilter"><span>{{'taskDescription' | translate}}</span> <i class="ph-arrows-down-up" (click)="sortTable('describtion')"></i></th>
                            <th scope="col" class="thWithFilter"><span>{{'taskdate' | translate}}</span> <i class="ph-arrows-down-up" (click)="sortTable('createDate')"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of pagedNotificationsData; let i = index">
                            <td>{{ item.name }}</td>
                            <td>{{ item.describtion }}</td>
                            <td>{{ item.createDate | dateFormat }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="pageniationCom">
                    <app-pagination [totalItems]="totalNotificationsItems" [itemsPerPage]="itemsNotificationsPerPage" (pageChange)="setPage($event)">
                    </app-pagination>

                    <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">
                        يعرض  <span *ngIf="Notifications.length < 10">{{Notifications.length}}</span> <span *ngIf="Notifications.length > 10 || Notifications.length === 10">10</span> من {{Notifications.length}}
                    </div>
                </div>
            </div>

            <div *ngIf="noNotifications" class="table_card_NoData">
                <div class="nodataCon">
                    <img src="../../../../assets/images/EmptyIcon.png" alt="noData">
                    <h5>{{'noData' | translate}}</h5>
                </div>
            </div>
        </div>
    </div>


</section>
