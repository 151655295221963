<nav>
    <ul class="pagination">
        <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="changePage(1)"><img src="../../../assets/images/pagination/full-right.png" title="البداية"></a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === 1">
            <a class="page-link" (click)="changePage(currentPage - 1)"><img src="../../../assets/images/pagination/right.png" title="السابق"></a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === pages.length">
            <a class="page-link" (click)="changePage(currentPage + 1)"><img src="../../../assets/images/pagination/left.png" title="التالي"></a>
        </li>
        <li class="page-item" [class.disabled]="currentPage === pages.length">
            <a class="page-link" (click)="changePage(pages.length)"><img src="../../../assets/images/pagination/full-left.png" alt="النهاية"></a>
        </li>
    </ul>
</nav>