import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { locale, loadMessages } from 'devextreme/localization';
import messages from '../assets/data/devextreme/locale.json';
import Dynamsoft from 'dwt';

Dynamsoft.DWT.ProductKey = 't01529gIAAFgQdFXSNJKSuLsnmRs6ryzC1z+o1FeE2gAmJFo/Q/hZWOSLz+owY6BDZDunMqOW3YdL7p9bmWg9oGyEvLc8BQtIzY0iMFi6qK9KbSrO6AauHXAA+CJ70F/5Aje+b6MKDmgGmIDejR/gOOSnVxqE9EAzwAQch3Qg6GRt31QehKwjpIRAM8AEjJAOGJdaLjY/AYRgmhc=';
Dynamsoft.DWT.ResourcesPath = 'assets/dwt-resources';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'نظام الإسكان | قوات الأمن الخاص';

  loadingScreen: boolean = true;

  constructor() {
    this.loadLocalizationMessages();
  }

  loadLocalizationMessages() {
    loadMessages(messages);
    locale('ar'); // Set to Arabic
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loadingScreen = false;
    }, 3000);
  }

}
