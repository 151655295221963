import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiObjectData } from '../models/api-object-data';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})

export class NotificationService {

    controller = 'Notification';
    constructor(private base: BaseService) {}

    getAllCurrentNotificationForUser(id:number){
      return this.base.gets(this.controller + '/getAllCurrentNotificationForUser/' + id)
    }
}