import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
    {
        id: 47,
        label: 'Dashboard',
        icon: 'ph-chart-pie-slice',
        link: '/dashboard',
    },
    {
        id: 1,
        label: 'Housing',
        icon: 'ph-house',
        subItems: [
            {
                id: 2,
                label: 'HousingRequests',
                link: '/housing/housing-requests',
                parentId: 1
            },
            {
                id: 3,
                label: 'DischargeRequests',
                link: '/housing/discharge-rquests',
                parentId: 1
            },
            {
                id: 4,
                label: 'inactiveRequests',
                link: '/housing/inactive-requests',
                parentId: 1
            },
            {
                id: 5,
                label: 'housingOrders',
                link: '/housing/housing-orders',
                parentId: 1
            },
            {
                id: 6,
                label: 'HousingEligible',
                link: '/housing/housing-eligible',
                parentId: 1
            },
            {
                id: 7,
                label: 'Residents',
                link: '/housing/residents',
                parentId: 1
            },
            {
                id: 65,
                label: 'upgrade-requests',
                link:'housing/upgrade-requests',
                parent: 1
            },
            {
                id: 8,
                label: 'Reports',
                link: '/housing/reports',
                parentId: 1
            },
            {
                id: 9,
                label: 'discharge-orders',
                link: '/housing/discharge-orders',
                parentId: 1
            },
            {
                id:10,
                label:'extend-evacuation',
                link:'/housing/extend-evacuation',
                parentId: 1
            },
            {
                id: 11,
                label:'retired',
                link:'/housing/retired',
                parentId: 1
            },
            {
                id: 12,
                label: 'retire-reasons',
                link: '/housing/retire-reasons',
                parentId: 1
            },
            {
                id: 13,
                label: 'Services',
                link: '/housing/services',
                parentId: 1
            },
            {
                id: 14,
                label: 'HousingUnits',
                link: '/housing/housing-units',
                parentId: 1
            },
            {
                id: 15,
                label: 'WaitingLists',
                link: '/housing/waiting-lists',
                parentId: 1
            },
            {
                id: 16,
                label: 'waitings',
                link: 'housing/waitings',
                parentId: 1
            },
            {
                id:17,
                label:'evacuted',
                link:'/housing/evacuted',
                parentId: 1
            }
        ]
    },
    {
        id: 18,
        label: 'Maintenance',
        icon: 'ph-wrench', 
        subItems: [
            {
                id: 19,
                label: 'MaintainanceRequests',
                link: '/maintainance/maintainance-requests',
                parentId: 18
            },
            {
                id: 20,
                label: 'work-shop',
                link: '/maintainance/work-shop',
                parentId: 18
            },
            {
                id: 21,
                label: 'repairRquest',
                link: '/maintainance/repair-request',
                parentId: 18
            },


            {
              id:22,
              label:'spareParts',
              link:'/maintainance/spare-parts',
              parentId:18

            },
            {
              id:23,
              label:'receivingOrders',
              link:'/maintainance/receiving-orders',
              parentId:18
            },
            {
                id: 24,
                label: 'regular',
                link: '/maintainance/regular-maintenance',
                parentId: 18
            },
            {
                id:25,
                label:"request-regular-maint",
                link:"/maintainance/request-regular-maint",
                parentId:18
            },

            
        ]
    },
    {
      id:56,
      label:'finance',
      icon:'ph-calculator',
      subItems: [
        {
            id: 57,
            label: 'FinancialDurations',
            link: '/finance/finance-durations',
            parentId:56, 
        },
        {
            id:58,
            label: 'userRents',
            link: '/finance/user-rents',
            parentId:56,
        },
        {
          id:59,
          label:'shopRents',
          link:'/finance/shop-rents',
          parentId:56
        },
        {
          id:60,
          label:'evaquated-rents',
          link:'/finance/evaquated-rents',
          parentId:56
        },
        {
            id:62,
            label:'checkDeposit',
            link:'/finance/check-deposit',
            parentId:56
        },
        {
            id:63,
            label:'uploadFile',
            link:'/finance/upload-rents',
            parentId:56
        },
    ]

    },
    {
        id: 26,
        label: 'companies',
        icon: 'ph-buildings', 
        subItems: [
            {
                id: 27,
                label: 'companies',
                link: '/companies/companies',
                parentId: 26
            },
            {
                id: 28,
                label: 'companies-contracts',
                link: '/companies/companies-contracts',
                parentId: 26
            },
            {
                id: 29,
                label: 'companies-interms',
                link: '/companies/companies-interms',
                parentId: 26
            },
            {
                id: 30,
                label: 'companies-spreparts',
                link: '/companies/companies-spreparts',
                parentId: 26
            },
        ]
    },
    {
        id: 32,
        label: 'approvals',
        icon: 'ph-thumbs-up', 
        link: '/approvals/approvals-levels',
    },
    {
        id: 33,
        label: 'warehouse',
        icon: 'ph-package', 
        subItems: [
            {
                id: 48,
                label: 'issueOrder',
                link: '/warehouse/issue-order',
                parentId: 33
            },
            {
                id: 49,
                label: 'issuedOrders',
                link: '/warehouse/issued-orders',
                parentId: 33
            },
            {
                id: 50,
                label: 'returnOrder',
                link: '/warehouse/return-order',
                parentId: 33
            },
            {
                id: 51,
                label: 'returnedOrders',
                link: '/warehouse/return-orders',
                parentId: 33
            },
            {
                id: 35,
                label: 'driverHousingrequest',
                link: '/warehouse/driver-housing-request',
                parentId: 33
            },
            {
                id: 52,
                label: 'drivers-requests',
                link: '/warehouse/drivers-requests',
                parentId: 33
            },
            {
                id: 34,
                label: 'warehouses',
                link: '/warehouse/warehouses',
                parentId: 33
            },
            {
                id: 36,
                label: 'categories',
                link: '/warehouse/categories',
                parentId: 33
            },
            {
                id: 37,
                label: 'items',
                link: '/warehouse/items',
                parentId: 33
            },
            {
                id: 41,
                label: 'Units',
                link: '/warehouse/units',
                parentId: 33
            },
            {
                id: 38,
                label: 'supplier-classes',
                link: '/warehouse/supplier-classes',
                parentId: 33
            },
            {
                id: 39,
                label: 'suppliers',
                link: '/warehouse/suppliers',
                parentId: 33
            },
            {
                id: 53,
                label: 'warehouseunits',
                link: '/warehouse/units',
                parentId: 33
            },
            {
                id: 42,
                label: 'externalCustomers',
                link: '/warehouse/external-customers',
                parentId: 33
            },
            {
                id: 64,
                label: 'inventory',
                link: '/warehouse/inventory',
                parentId: 33
            }
        ]
    },
    {
        id: 43,
        label: 'administration',
        icon: 'ph-user-circle-gear', 
        subItems: [
            {
                id: 44,
                label: 'lookups',
                link: '/administration/lookups',
                parentId: 43
            },
            {
                id: 54,
                label: 'roles',
                link: '/administration/roles',
                parentId: 43
            },
            {
                id: 55,
                label: 'roleManagment',
                link: '/administration/role-management',
                parentId: 43
            },
            {
                id: 45,
                label: 'users',
                link: '/administration/users',
                parentId: 43
            },
            {
                id: 46,
                label: 'actions',
                link: '/administration/actions',
                parentId: 43
            },
        ]
    },
]